<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-switch color="success" v-model="enable" hide-details
                      :label="enable ? 'Включено' : 'Виключено' "
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                hide-details
                filled
                label="Вид запису"
                :items="pay_system_row_type_select"
                v-model="row_type"
                :class="row_type ? '' : 'req-star'"
                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                hide-details
                filled
                label="Тип шаблону"
                :items="pay_system_template_type_select"
                v-model="template_type"
                :class="template_type ? '' : 'req-star'"
                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                @change="onTemplateTypeChange"
                color="grey"/>
          </v-col>
          <v-col cols="12">
            <v-text-field hide-details
                          v-model="url_template"
                          filled
                          label="URL Шаблон"
                          color="grey"
                          :class="row_type ? '' : 'req-star'"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details
                          v-model="button_name"
                          filled
                          label="Назва команди"
                          color="grey"
                          :class="button_name ? '' : 'req-star'"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field hide-details
                          v-model="icon"
                          filled
                          label="Іконка"
                          color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea auto-grow color="grey" hide-details filled
                        v-model="data_" rows="3"
                        label="JSON дані"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {CREATE_PAY_SYSTEM, DELETE_PAY_SYSTEM, UPDATE_PAY_SYSTEM} from "@/store/actions/pay_system";
import {pay_system_row_type_select, pay_system_template_type_select} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'bases_by_service__modal_delete'

export default {
  name: "HWP_Modal_PaySystem",
  mixins: [ModalComponentMixin],
  data() {
    return {
      pay_system_row_type_select,
      pay_system_template_type_select,
      navigation: false,
      template_type: this.item.template_type,
      row_type: this.item.row_type,
      url_template: this.item.url_template,
      enable: this.item.enable,
      data_: this.item.data || '{}',
      button_name: this.item.button_name,
      icon: this.item.icon,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.row_type = this.item.row_type
      this.template_type = this.item.template_type
      this.url_template = this.item.url_template
      this.enable = this.item.enable
      this.data_ = this.item.data || '{}'
      this.button_name = this.item.button_name
      this.icon = this.item.icon

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення налаштувань платіжнихз систем`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    onTemplateTypeChange(payload) {
      this.$nextTick(() => {
        if (!payload) return
        if (payload === 'eps') {
          this.url_template = 'https://eps.org.ua/redirect/{{region}}/service/{{eps_pay_id}}/id/{{person_id}}'
          this.data_ = '{"region": "", "eps_pay_id": "", "button_color": "primary"}'
          this.icon = 'mdi-credit-card-outline'
          this.button_name = 'Єдина квитанція (ПриватБанк)'
        }
        if (payload === 'eps_ipay') {
          this.url_template = 'https://eps.org.ua/redirect/{{region}}/service/{{eps_pay_id}}/id/{{person_id}}/ipay'
          this.data_ = '{"region": "", "eps_pay_id": "", "button_color": "error lighten-1"}'
          this.icon = 'mdi-credit-card-outline'
          this.button_name = 'Єдина квитанція (ipay)'
        }
        if (payload === 'privat_bank') {
          this.url_template = `https://next.privat24.ua/payments/form/{"token":"{{privat_pay_id}}","personalAccount":"{{person_id}}"}`
          this.data_ = '{"privat_pay_id": "", "button_color": "success"}'
          this.icon = ''
          this.button_name = 'Оплата через Privat24'
        }
      })
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі обовязкові поля мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }
      const json_data = JSON.parse(this.data_ || '{}')

      const payload = {
        row_type: this.row_type,
        template_type: this.template_type,
        url_template: this.url_template,
        enable: this.enable,
        data: json_data,
        button_name: this.button_name,
        icon: this.icon
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PAY_SYSTEM, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_PAY_SYSTEM, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.row_type = payload.row_type
              this.template_type = payload.template_type
              this.url_template = payload.url_template
              this.enable = payload.enable
              this.data_ = payload.data
              this.button_name = payload.button_name
              this.icon = payload.icon
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_PAY_SYSTEM, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
